<template>
  <div>
    <div class="columns">
      <div class="column center">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h2>Unstake</h2>
              <b-field>
                <b-input placeholder="Amount" rounded v-model="amount"></b-input>
              </b-field>
              <b-field class="left">
                <a href="#" class="max-size" @click="getMaxValue">
                  Max {{ $store.getters.tunaMaxValue }} TUNA
                </a>
              </b-field>
              <b-field grouped class="center-column">
                <b-tooltip position="is-top" type="is-dark"
                           multilined label="Regardless of having TON for the withdrawal,
                         you will receive NFT which will be converted to TON at the end of the
                         round using the TUNA/TON rate">
                  <b-switch v-model="isWaitTillRoundEnd" type="is-success">
                    waitTillRoundEnd
                  </b-switch>
                </b-tooltip>
                <b-tooltip position="is-top" type="is-dark"
                         multilined label="If there is no way to withdrawal TON immediately because
                         it is in validation, you will get Jettons back, not NFT, which will be
                         converted to TON at the end of the round">
                  <b-switch v-model="isFillOrKill" type="is-success">
                    fillOrKill
                  </b-switch>
                </b-tooltip>
              </b-field>
              <b-field>
                <b-button type="is-info" expanded @click="unstake">Unstake</b-button>
              </b-field>
              <div class="total-fees">Total fees: ~{{ this.totalFees }} TON</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column center">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <p>
                When you unstake TUNA generally there are two options:<br>
                if there are enough unused TON on pool you can get TON immediately
                (at current rate, with current round profit discarded) or get NFT Withdrawal Bill
                which will be converted to TON and sent to your address
                after round end (at the rate after round end).
              </p>
              <p>By using switches you can control the mode:</p>
              <p>
                1) switching "waitTillRoundEnd" ON will force issuance of NFT Withdrawal Bill
                and allow you to keep this round profit.
              </p>
              <p>
                2) switching "fillOrKill" ON will forbid NFT issuance: if there are no enough
                TON on balance you will get your TUNA back.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { beginCell, address } from 'ton-core';
import { getDepositCustomDePayload, getTokenAddress } from '@/utils';
import { UNSTAKE_AMOUNT, TOTAL_FEES } from '@/config';

export default {
  data() {
    return {
      amount: '',
      isWaitTillRoundEnd: false,
      isFillOrKill: false,
      totalFees: TOTAL_FEES,
    };
  },
  methods: {
    async getMaxValue() {
      this.amount = this.$store.getters.tunaMaxValue;
    },
    async unstake() {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      // amount
      const amount = this.amount * 1e9;
      if (Number.isNaN(amount) || amount <= 0) {
        this.$buefy.toast.open('Incorrect amount entered');
        return;
      }

      // payload
      try {
        // get token address
        const tokenAddress = (await getTokenAddress(
          window.client4,
          address(window.connector.account.address),
          address(window.JETTON_ADDRESS),
        )).toString();
        console.log(tokenAddress);

        // payload
        const cell = beginCell()
          .storeUint(0x595f07bc, 32)
          .storeUint(0, 64) // op, queryId
          .storeCoins(amount)
          .storeAddress(address(window.connector.account.address))
          .storeMaybeRef(getDepositCustomDePayload(this.isWaitTillRoundEnd, this.isFillOrKill))
          .endCell();
        const payload = cell.toBoc();

        // transaction
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: tokenAddress,
              amount: UNSTAKE_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 500px;
  box-shadow: none;
}

.card p {
  text-align: justify;
}

.max-size {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  left: 20px;
}

.total-fees {
  text-align: right;
  font-size: 13px;
  opacity: 0.4;
}
</style>
