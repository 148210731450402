import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    connected: false,
    account: {
      address: '',
      tonBalance: 0,
      tunaBalance: 0,
    },
    stats: {
      currentRate: 0,
      nextRoundRate: 0,
      lockedTON: 0,
      jettonSupply: 0,
      currentlyBorrowed: 0,
      expectedRoundProfit: 0,
      jettonRoot: 'empty',
      jettonPool: 'empty',
      momentaryAPY: 0,
    },
  },
  getters: {
    tonBalance(state) {
      return `${state.account.tonBalance / 1e9}`;
    },
    tonMaxValue(state) {
      if (state.account.tonBalance <= 2000000000) {
        return '0';
      }

      return `${(state.account.tonBalance - 2000000000) / 1e9}`;
    },
    tunaMaxValue(state) {
      return `${state.account.tunaBalance / 1e9}`;
    },
  },
  mutations: {
    UPDATE_CONNECT_STATE(state, payload) {
      state.connected = payload;
    },
    UPDATE_ACCOUNT_STATE(state, payload) {
      state.account = payload;
    },
    UPDATE_STATS(state, payload) {
      state.stats = payload;
    },
  },
  actions: {
    updateConnectState(context, payload) {
      context.commit('UPDATE_CONNECT_STATE', payload);
    },
    updateAccountState(context, payload) {
      context.commit('UPDATE_ACCOUNT_STATE', payload);
    },
    resetAccountState(context) {
      context.commit('UPDATE_ACCOUNT_STATE', {
        address: '',
        tonBalance: 0,
        tunaBalance: 0,
      });
    },
    updateStats(context, payload) {
      context.commit('UPDATE_STATS', payload);
    },
  },
  modules: {
  },
});
