import { TonClient4, Address } from 'ton';
import { TupleBuilder, beginCell } from 'ton-core';

export const getFullData = async (client: TonClient4, poolAddress: Address) => {
  const { last: { seqno } } = await client.getLastBlock();
  const { reader } = await client.runMethod(seqno, poolAddress, 'get_pool_full_data_raw', []);
  const stack = reader;

  const state = Number(stack.readBigNumber());
  const halted = Number(stack.readBigNumber());
  const totalBalance = stack.readBigNumber();
  const interestRate = Number(stack.readBigNumber());
  const optimisticDepositWithdrawals = Number(stack.readBigNumber());
  const depositsOpen = Number(stack.readBigNumber());
  const savedValidatorSetHash = stack.readBigNumber();

  const prv = stack.readTuple();
  const prvBorrowers = prv.readCellOpt();
  const prvRoundId = Number(prv.readBigNumber());
  const prvActiveBorrowers = prv.readBigNumber();
  const prvBorrowed = prv.readBigNumber();
  const prvExpected = prv.readBigNumber();
  const prvReturned = prv.readBigNumber();
  const prvProfit = prv.readBigNumber();
  const previousRound = {
    borrowers: prvBorrowers,
    roundId: prvRoundId,
    activeBorrowers: prvActiveBorrowers,
    borrowed: prvBorrowed,
    expected: prvExpected,
    returned: prvReturned,
    profit: prvProfit,
  };

  const cur = stack.readTuple();
  const curBorrowers = cur.readCellOpt();
  const curRoundId = Number(cur.readBigNumber());
  const curActiveBorrowers = cur.readBigNumber();
  const curBorrowed = cur.readBigNumber();
  const curExpected = cur.readBigNumber();
  const curReturned = cur.readBigNumber();
  const curProfit = cur.readBigNumber();
  const currentRound = {
    borrowers: curBorrowers,
    roundId: curRoundId,
    activeBorrowers: curActiveBorrowers,
    borrowed: curBorrowed,
    expected: curExpected,
    returned: curReturned,
    profit: curProfit,
  };

  const minLoan = stack.readBigNumber();
  const maxLoan = stack.readBigNumber();
  const governanceFee = Number(stack.readBigNumber());

  const poolJettonMinter = stack.readAddress();
  const poolJettonSupply = stack.readBigNumber();

  const depositPayout = stack.readAddressOpt();
  const requestedForDeposit = stack.readBigNumber();

  const withdrawalPayout = stack.readAddressOpt();
  const requestedForWithdrawal = stack.readBigNumber();

  const sudoer = stack.readAddress();
  const sudoerSetAt = Number(stack.readBigNumber());

  const governor = stack.readAddress();
  const governorUpdateAfter = Number(stack.readBigNumber());
  const interestManager = stack.readAddress();
  const halter = stack.readAddress();
  const approver = stack.readAddress();

  const controllerCode = stack.readCell();
  const jettonWalletCode = stack.readCell();
  const payoutMinterCode = stack.readCell();

  const projectedTotalBalance = stack.readBigNumber();
  const projectedPoolSupply = stack.readBigNumber();

  return {
    state,
    halted,
    totalBalance,
    interestRate,
    optimisticDepositWithdrawals,
    depositsOpen,
    savedValidatorSetHash,

    previousRound,
    currentRound,

    minLoan,
    maxLoan,
    governanceFee,

    poolJettonMinter,
    poolJettonSupply,
    supply: poolJettonSupply,
    depositPayout,
    requestedForDeposit,
    withdrawalPayout,
    requestedForWithdrawal,

    sudoer,
    sudoerSetAt,
    governor,
    interestManager,
    halter,
    approver,

    controllerCode,
    jettonWalletCode,
    payoutMinterCode,
    projectedPoolSupply,
    projectedTotalBalance,
  };
};

export const getTokenAddress = async (
  client: TonClient4,
  accountAddress: Address,
  jettonAddress: Address,
) => {
  const { last: { seqno } } = await client.getLastBlock();

  const stackBuilder = new TupleBuilder();
  stackBuilder.writeAddress(accountAddress);
  const stack = stackBuilder.build();

  const minterData = await client.runMethod(seqno, jettonAddress, 'get_wallet_address', stack);
  const tokenAddress = minterData.reader.readAddress();

  return tokenAddress;
};

export const getAccountState = async (
  client: TonClient4,
  accountAddress: Address,
  jettonAddress: Address,
) => {
  const { last: { seqno } } = await client.getLastBlock();
  const { account: { balance: { coins } } } = await client.getAccount(seqno, accountAddress);

  const stackBuilder = new TupleBuilder();
  stackBuilder.writeAddress(accountAddress);
  const stack = stackBuilder.build();

  let tokens = 0;
  const minterData = await client.runMethod(seqno, jettonAddress, 'get_wallet_address', stack);
  if (minterData.exitCode === 0) {
    const tokenAddress = minterData.reader.readAddress();
    const tokenData = await client.runMethod(seqno, tokenAddress, 'get_wallet_data');
    if (tokenData.exitCode === 0) {
      tokens = tokenData.reader.readNumber();
    }
  }

  return {
    address: accountAddress.toString({ urlSafe: true, bounceable: true, testOnly: true }),
    tonBalance: Number(coins),
    tunaBalance: Number(tokens),
  };
};

export const getDepositCustomDePayload = (waitTillRoundEnd: boolean, fillOrKill: boolean) => {
  const customPayload = beginCell()
    .storeUint(Number(waitTillRoundEnd), 1)
    .storeUint(Number(fillOrKill), 1).endCell();
  return customPayload;
};

export const getPrettifyAddress = (nonFormatAddress: string) => `${nonFormatAddress.slice(0, 6)}...${nonFormatAddress.slice(-6)}`;

export const getStats = (data:any, poolAddress: Address) => {
  const profit = data.previousRound.expected - data.previousRound.borrowed;
  const percentPerRound = Number(profit) / Number(data.totalBalance);
  const roundPeriod = 7200;
  // eslint-disable-next-line no-mixed-operators
  const apy = (((1 + percentPerRound) ** (3600 * 24 * 365 / roundPeriod) - 1) * 100).toFixed(2);

  return {
    currentRate: (Number(data.totalBalance) / Number(data.poolJettonSupply))
      .toFixed(9),
    nextRoundRate: (Number(data.projectedTotalBalance) / Number(data.projectedPoolSupply))
      .toFixed(9),
    lockedTON: (Number(data.totalBalance) / 1e9).toFixed(9),
    jettonSupply: (Number(data.poolJettonSupply) / 1e9).toFixed(9),
    currentlyBorrowed: ((Number(data.previousRound.borrowed)
      - Number(data.previousRound.returned)
      + Number(data.currentRound.borrowed)
      - Number(data.currentRound.returned)) / 1e9)
      .toFixed(9),
    expectedRoundProfit: ((Number(data.currentRound.expected)
      - Number(data.currentRound.borrowed)) / 1e9).toFixed(9),
    jettonRoot: data.poolJettonMinter.toString({
      urlSafe: true,
      bounceable: true,
      testOnly: true,
    }),
    jettonPool: poolAddress.toString({
      urlSafe: true,
      bounceable: true,
      testOnly: true,
    }),
    momentaryAPY: apy,
  };
};
