<template>
  <div>
    <div class="columns">
      <div class="column center">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h2>Stake</h2>
              <b-field>
                <b-input placeholder="Amount" rounded v-model="amount"></b-input>
              </b-field>
              <b-field class="left">
                <a href="#" class="max-size" @click="getMaxValue">
                  Max {{ $store.getters.tonMaxValue }} TON
                </a>
              </b-field>
              <b-field>
                <b-button type="is-info" expanded @click="stake">Stake</b-button>
              </b-field>
              <div class="total-fees">Total fees: ~{{ this.totalFees }} TON</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column center">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <table class="table stats">
                <tbody>
                  <tr>
                    <td>
                      Current TUNA/TON<br><small>(this rate will be used for withdrawals)</small>
                    </td>
                    <td>{{ $store.state.stats.currentRate }}</td>
                  </tr>
                  <tr>
                    <td>
                      Next round TUNA/TON<br><small>(this rate will be used for deposits)</small>
                    </td>
                    <td>{{ $store.state.stats.nextRoundRate }}</td>
                  </tr>
                  <tr>
                    <td>Locked TONs</td>
                    <td>{{ $store.state.stats.lockedTON }}</td>
                  </tr>
                  <tr>
                    <td>Jetton supply</td>
                    <td>{{ $store.state.stats.jettonSupply }}</td>
                  </tr>
                  <tr>
                    <td>Currently Borrowed</td>
                    <td>{{ $store.state.stats.currentlyBorrowed }}</td>
                  </tr>
                  <tr>
                    <td>Expected round profit</td>
                    <td>{{ $store.state.stats.expectedRoundProfit }}</td>
                  </tr>
                  <tr>
                    <td>Jetton Root</td>
                    <td><a target="_blank" :href="'https://testnet.ton.cx/address/' + $store.state.stats.jettonRoot">{{ this.getPrettifyJettonRootAddress }}</a></td>
                  </tr>
                  <tr>
                    <td>Jetton Pool</td>
                    <td><a target="_blank" :href="'https://testnet.ton.cx/address/' + $store.state.stats.jettonPool">{{ this.getPrettifyJettonPoolAddress }}</a></td>
                  </tr>
                  <tr>
                    <td>
                      Momentary APY<br><small>(yearly extrapolated previous round profit)</small>
                    </td>
                    <td>{{ $store.state.stats.momentaryAPY }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { beginCell } from 'ton-core';
import { STAKE_AMOUNT, TOTAL_FEES } from '@/config';
import { getPrettifyAddress } from '@/utils';

export default {
  data() {
    return {
      amount: '',
      jettonPool: window.POOL_ADDRESS,
      totalFees: TOTAL_FEES,
    };
  },
  computed: {
    getPrettifyJettonPoolAddress() {
      if (this.$store.state.stats.jettonPool !== 'empty') {
        return getPrettifyAddress(this.$store.state.stats.jettonPool);
      }
      return this.$store.state.stats.jettonPool;
    },
    getPrettifyJettonRootAddress() {
      if (this.$store.state.stats.jettonRoot !== 'empty') {
        return getPrettifyAddress(this.$store.state.stats.jettonRoot);
      }
      return this.$store.state.stats.jettonRoot;
    },
  },
  methods: {
    async getMaxValue() {
      this.amount = this.$store.getters.tonMaxValue;
    },
    async stake() {
      if (!window.connector.connected) {
        this.$buefy.toast.open('Wallet is not connected');
        return;
      }

      if (window.connector.account.chain !== '-3') {
        this.$buefy.toast.open('Switch the wallet to the testnet network');
        return;
      }

      // amount
      const amount = this.amount * 1e9;
      if (Number.isNaN(amount) || amount <= 0) {
        this.$buefy.toast.open('Incorrect amount entered');
        return;
      }

      // payload
      const cell = beginCell()
        .storeUint(0x47d54391, 32) // op = pool::deposit
        .storeUint(1, 64) // query id
        .endCell();
      const payload = cell.toBoc();

      // transaction
      try {
        const transaction = {
          validUntil: +new Date() + 5 * 60 * 1000,
          messages: [
            {
              address: window.POOL_ADDRESS,
              amount: amount + STAKE_AMOUNT,
              payload: payload.toString('base64'),
            },
          ],
        };

        await window.connector.sendTransaction(transaction);
        this.$buefy.toast.open({ message: 'Transaction was sent successfully', type: 'is-success' });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  width: 500px;
  box-shadow: none;
}

.max-size {
  font-size: 12px;
  font-weight: 700;
  position: relative;
  left: 20px;
}

.stats td:first-child {
  text-align: left;
}

.stats td:last-child {
  text-align: right;
}

.total-fees {
  text-align: right;
  font-size: 13px;
  opacity: 0.4;
}
</style>
