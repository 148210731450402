import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import StakeView from '../views/StakeView.vue';
import UnstakeView from '../views/UnstakeView.vue';
import ValidatorView from '../views/ValidatorView.vue';
import AboutView from '../views/AboutView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'stake',
    component: StakeView,
  },
  {
    path: '/unstake',
    name: 'unstake',
    component: UnstakeView,
  },
  {
    path: '/validator',
    name: 'validator',
    component: ValidatorView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
